// default settings (d_ for default)
var d_custom = {
    max_time: 45,
    code: '397854',
    // code: '111111',
    music: true, // <-- ik vind mijn eigen muziek toch fijner :)
    tasks: {
        1: {
            code: '021',
            // code: '5555',
            letter: 'M'
        },
        2: {
            code: '8394',
            // code: '5555',
            letter: 'H'
        },
        3: {
            code: '1948',
            // code: '5555',
            letter: 'U'
        },
        4: {
            code: '2',
            // code: '5555',
            letter: 'B'
        },
        5: {
            code: '2007',
            // code: '5555',
            letter: 'E'
        },
        6: {
            code: '7523',
            // code: '5555',
            letter: 'R'
        },
    }
}

const d_content = {
    1: {
        title: "Mentaal welbevinden",
        content: `Type hier de code die jullie gevonden hebben. 
Je ontvangt, na invoer van de juiste code, 
een letter. 
Deze letter heb je nodig in het eindspel.`,
        icon: "dimensie-mentaal_welbevinden.svg"
    },
    2: {
        title: "Meedoen",
        content: `Hebben jullie de 8 gesprektools "Hoe gaat het met u" gevonden? 
De nummers links geven de plek van het cijfer in de code weer.
Koppel de nummers aan elkaar.
Bepaal wie van de twee personen het meest positief gezond is.`,
        icon: "dimensie-sociaal_maatschappelijk_participeren.svg",
    },
    3: {
        title: "Lichaamsfunctie",
        content: `Type hier de code die jullie gevonden hebben. 
Je ontvangt, na invoer van de juiste code, 
een letter. 
Deze letter heb je nodig in het eindspel.`,
        icon: "dimensie-lichaamsfuncties.svg",
    },
    4: {
        title: "Dagelijks functioneren",
        content: ``,
        icon: "dimensie-dagelijks_functioneren.svg",
    },
    5: {
        title: "Kwaliteit van leven",
        content: `Type hier de code die jullie gevonden hebben. 
Je ontvangt, na invoer van de juiste code, 
een letter. 
Deze letter heb je nodig in het eindspel.`,
        icon: "dimensie-kwaliteit_van_leven.svg",
    },
    6: {
        title: "Zingeving",
        content: `Type hier de code die jullie gevonden hebben. 
Je ontvangt, na invoer van de juiste code, 
een letter. 
Deze letter heb je nodig in het eindspel.`,
        icon: "dimensie-zingeving.svg",
    },
    mainContent: {
        content: `Ga naar https://www.syntein.nl/MHUBER
        TIP: leg de juiste 6 cijfers bij de icoontjes van het spinnenweb.
        Succes, jullie zijn bijna bij de oplossing!`,
    },

    controlcode: "102007",
}

const d_tips = {
    43: "hebben jullie al ontdekt dat de 6 icoontjes op de app jullie gaan helpen?",
    37: "hebben jullie de perforator goed bekeken?",
    35: "als alle 6 letters zijn gevonden dan volgt er verdere instructie via de app.",
    32: "hebben jullie al ontdekt dat niet alle 4 boekjes hetzelfde zijn?",
    25: "hebben jullie gezien dat op de achterkant van de puzzel een code staat?",
    17: "zijn jullie al met het filmpje bezig? Zo niet dan wordt het wel tijd!",
    15: "de volgorde van de dimensies op de tijdbom bepalen de volgorde van de cijfers in de ontmantelingscode."
}

const d_music = {
    start: require("@/assets/audio/Daniel_Birch_-_05_-_Fluid_Dreams.mp3"),
    15: require("@/assets/audio/Rafael-Krux-Alien-Invasion.mp3")
}





// imports
import {
    reactive
} from "vue"

export default function getSettings() {

    const custom = new reactive(d_custom)
    const content = d_content
    const tips = d_tips
    const music = d_music



    return { custom, content, tips, music }
}