<template>
<div class="container_start">
    <router-link :to="{ name: 'GameMain' }" class="btn_start">Start</router-link>
</div>
</template>

<script>
export default {
    name: 'Start',

}
</script>

<style>
.container_start {
    margin: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn_start {
    color: white;
    text-decoration: none;
    font-size: 9em;
    display: inline-block;
    margin: auto;
}

.btn_start:hover {
    color: lightgray;
}
</style>
