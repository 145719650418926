<template>
  <div class="row firstrow task">
    <timer />

    <h1 :class="'title task_' + task_id">{{ title }}</h1>

    <router-link
      :to="{ name: 'GameMain' }"
      @click="playSound('icon_click')"
      :class="'btn close task_' + task_id"
    >
      <img class="img_close" :src="require('@/assets/icons/close.svg')" />
    </router-link>
  </div>
  <tips />
  <div class="row task-main">
    <div class="col content">
      <p>{{ content }}</p>
    </div>
    <div class="task-num-col">
      <numpad v-bind:code="code" v-bind:letter="letter" :taskid="task_id" />
    </div>
  </div>
</template>

<script>
import numpad from "@/components/Numpad.vue";
import timer from "@/components/Timer.vue";
import tips from "@/components/Tips.vue";

import { onBeforeRouteLeave, useRoute } from "vue-router";
import { ref, watch, inject } from "vue";

export default {
  components: {
    tips,
    numpad,
    timer,
  },
  emits: ["complete-task"],
  setup() {
    const route = useRoute();

    const s_custom = inject("s_custom");
    const s_content = inject("s_content");

    const playSound = inject("playSound");

    const currentCode = inject("currentCode");

    const smallTimer = inject("smallTimer");
    smallTimer.value = true;

    const task_id = new ref(route.params.id);

    const title = new ref(s_content[task_id.value].title);
    const content = new ref(s_content[task_id.value].content);
    const code = new ref(s_custom.tasks[task_id.value].code);
    const letter = new ref(s_custom.tasks[task_id.value].letter);

    // fetch new data when params change
    watch(() => route.params.id, fetchParams);

    function fetchParams(id) {
      if (typeof id != "undefined") {
        task_id.value = id;
        title.value = s_content[task_id.value].title;
        content.value = s_content[task_id.value].content;
        code.value = s_custom.tasks[task_id.value].code;
        letter.value = s_custom.tasks[task_id.value].letter;

        currentCode.value = "";
      }
    }

    onBeforeRouteLeave(function () {
      currentCode.value = "";
    });

    // function completeTask() {
    //     emit('complete-task', [task_id.value, letter.value])
    // }

    return {
      title,
      content,
      code,
      letter,
      task_id,
      // completeTask
      playSound,
    };
  },
};
</script>

<style>
.btn.close {
  text-decoration: none;
  margin: auto 0;
  display: flex;
}

.img_close {
  width: 30px;
  height: 30px;
  margin: auto;
}

.btn {
  width: 50px;
  height: 50px;
  line-height: 1.45em;
}
</style>
