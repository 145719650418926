import { ref, inject } from 'vue'
import router from '@/router'

export default function getTimerState() {

    const s_custom = inject("s_custom")
    // tips
    const s_tips = inject("s_tips")
    const availableTips = inject("availableTips")
    // finish content
    const c_finish = inject('c_finish')
    // music
    const s_music = inject("s_music")
    const currentMusic = inject("currentMusic")
    const playSound = inject('playSound')

    const max_time = new ref(s_custom.max_time * 60)
    // const max_time = new ref((43 * 60) + 5)
    const timePassed = new ref(0)

    const time_left = new ref('00:00')
    // const final_time = new ref('00:00')

    const timerInterval = new ref(0)

    const startTimer = () => {
        // set music
        currentMusic.value = s_music.start
        // set time
        formattedTimeLeft()
        // start countdown
        timerInterval.value = setInterval(function () {
            timePassed.value++
            formattedTimeLeft()
        }, 1000)
    }

    const circleDasharray = () => {
        return `${(timeFraction() * 283).toFixed(0)} 283`
    }

    const stopTime = () => {
        clearInterval(timerInterval.value)
        c_finish.time = FormatTime(timePassed.value)
        router.push({
            name: 'Finish'
        })
    }


    function formattedTimeLeft() {
        const timeLeft = (max_time.value - timePassed.value)

        time_left.value = FormatTime(timeLeft)

        // once a minute
        if ((timeLeft % 60) == 0) {
            checkForChanges(Math.floor(timeLeft / 60))
        }
        if (timeLeft == 0) {
            c_finish.finished = 'fail'
            stopTime()
        }
    }

    function FormatTime(time) {
        const minutes = Math.floor(time / 60)
        let seconds = time % 60
        if (seconds < 10) {
            seconds = `0${seconds}`
        }
        return `${minutes}:${seconds}`
    }

    function timeFraction() {
        // let timeLeft = (max_time.value - timePassed.value) / max_time
        return (max_time.value - timePassed.value) / max_time.value;
    }

    function checkForChanges(minute) {
        Object.entries(s_tips).forEach(([min, tip]) => {
            if (minute == min) {
                // add tip
                playSound('show_tip')
                availableTips.value.push(tip)
            }
        })
        Object.entries(s_music).forEach(([min, file]) => {
            if (minute == min) {
                // add tip
                currentMusic.value = file
            }
        })
    }

    return {
        // data
        time_left, // remaining time

        // functions
        startTimer, // starts the timer
        stopTime,   // stops the timer
        circleDasharray, // svg that makes the timer look fancy

    }



}