<template>
<audio autoplay loop v-if="currentMusic != ''" id="audio-player" :src="currentMusic" />
</template>

<script>
import {
    inject,
    // watch
} from 'vue'
export default {
    setup() {
        // get audio file
        const currentMusic = inject('currentMusic')

        return {
            currentMusic
        }

    }

}
</script>
