/**
 * Particle effects
 *
 * different effects that can be called anywhere in the project
 * pe_ = particle effect
*/

// PE 1

function pe_pop_1(e, color_id = false, num_particles = 30) {
    let color = getcolor(color_id)
    for (let i = 0; i < num_particles; i++) {
        pop_1_particle(e.clientX, e.clientY, color)
    }

}

function pop_1_particle(x, y, color) {

    let particle = document.createElement('particle')
    document.body.appendChild(particle);

    const size = Math.floor(Math.random() * 15 + 2);

    particle.style.width = `${size}px`
    particle.style.height = `${size}px`
    particle.style.background = color
    particle.style.background = 'radial-gradient(circle, ' + color + ' 10%, rgba(0,0,0,0) 100%)';


    // set destiination
    const destinationX = x + (Math.random() - 0.5) * 2 * 150;
    const destinationY = y + (Math.random() - 0.5) * 2 * 150;

    const animation = particle.animate([
        {
            // Set the origin position of the particle
            // We offset the particle with half its size to center it around the mouse
            transform: `translate(${x - (size / 2)}px, ${y - (size / 2)}px)`,
            opacity: 1
        },
        {
            // We define the final coordinates as the second keyframe
            transform: `translate(${destinationX}px, ${destinationY}px)`,
            opacity: .3
        }
    ], {
        // Set a random duration
        duration: 100 + Math.random() * 500,
        easing: 'cubic-bezier(0, .9, .57, 1)',
        // Delay every particle with a random value from 0ms to 200ms
        delay: Math.random() * 200
    });

    // remove particles when done
    animation.onfinish = () => {
        particle.remove();
    }
}

// gets color based on task id
function getcolor(id) {
    switch (id) {
        case 1:
            return '#00A4E2'
        case 2:
            return '#ED7004'
        case 3:
            return '#E4032E'
        case 4:
            return '#95C11F'
        case 5:
            return '#F59C00'
        case 6:
            return '#832C87'

        default:
            return '#fff'
    }
}


export {
    pe_pop_1
}