<template>
  <music v-if="s_custom.music" />
  <div class="container">
    <router-view />

    <div class="row link_row">
      <div class="link_wrapper" v-for="i in 6" :key="i">
        <div
          class="with_letter"
          v-if="task_letters[i]"
          :class="{ inactive: i != task_id && typeof task_id != 'undefined' }"
        >
          <span class="link" :class="'task_' + i">{{ task_letters[i] }}</span>
          <!-- icon bottom right -->
          <img :src="require('@/assets/icons/' + s_content[i].icon)" />
        </div>
        <router-link
          :to="{ name: 'GameTask', params: { id: i } }"
          class="btn_task"
          v-else
        >
          <div
            class="no_letter"
            @click="particleEffect_1(i, $event)"
            :class="{ inactive: i != task_id && typeof task_id != 'undefined' }"
          >
            <img :src="require('@/assets/icons/' + s_content[i].icon)" />
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { pe_pop_1 } from "@/assets/js/particles.js";
// components
import music from "@/components/Music.vue";

// timer state
import getTimerState from "@/states/timer_state";

// google analytics
import { useGtag } from "vue-gtag-next";

// imports
import { useRoute, onBeforeRouteLeave } from "vue-router";
import { inject, provide, onMounted, watch, ref } from "vue";

export default {
  name: "Game",
  components: {
    music,
  },
  setup() {
    const { event } = useGtag();

    const route = useRoute();
    const task_id = new ref(route.params.id);
    watch(
      () => route.params.id,
      () => {
        task_id.value = route.params.id;
      }
    );

    // provide timer state over game views
    const timer_state = getTimerState();
    Object.entries(timer_state).forEach(([name, state]) => {
      provide(name, state);
    });

    const startTimer = () => timer_state.startTimer();

    const s_custom = inject("s_custom");

    const s_content = inject("s_content");
    const subtasksDone = inject("subtasksDone");

    const task_letters = inject("completedLetters");
    const tips = inject("availableTips");
    const wrong_codes = inject("wrongCodes");

    const playSound = inject("playSound");

    onBeforeRouteLeave(function (to) {
      if (to.name == "Beheer") {
        tips.value = [];
        wrong_codes.value = [];
        subtasksDone.value = false;
        Object.keys(task_letters).forEach((key) => {
          delete task_letters[key];
        });
      }
    });

    watch(
      () => Object.keys(task_letters).length,
      () => {
        if (Object.keys(task_letters).length == 6) {
          subtasksDone.value = true;
        }
      }
    );

    onMounted(() => {
      event("start_game", {
        event_category: "game",
        event_label: "Spel gestart",
      });

      startTimer();
    });

    function particleEffect_1(task_id, e) {
      playSound("icon_click");
      pe_pop_1(e, task_id, 50);
    }

    return {
      task_letters,
      s_content,
      task_id,
      s_custom,

      particleEffect_1,
    };
  },
};
</script>

<style>
.link_row {
  margin-top: 1em;
}

.link_wrapper {
  display: inherit;
  width: 150px;
  height: 125px;
}

.link_wrapper .with_letter {
  max-height: 150px;
}

.link_wrapper .with_letter span,
.link_wrapper .no_letter {
  width: 140px;
  min-width: 75px;
  height: 140px;
  min-height: 75px;
  border-radius: 50%;
  text-decoration: none;
}

.link_wrapper .no_letter img {
  width: 140px;
  min-width: 75px;
  height: 140px;
  min-height: 75px;
}

.link_wrapper .with_letter.inactive,
.link_wrapper .no_letter.inactive {
  opacity: 0.3;
}

.link_wrapper .no_letter.inactive:hover {
  opacity: 0.7;
}

.link_wrapper .with_letter span {
  color: #fff;
  font-size: 6em;
  line-height: 1.4em;
  text-transform: capitalize;
  display: inline-block;
}

.with_letter img {
  width: 50px;
  height: 50px;
  position: relative;
  bottom: 25%;
  left: 50%;
}

/* color codes */

.link.task_1,
.btn.task_1 {
  background-color: #00a4e2;
}

.btn.task_1:hover {
  background-color: #33c9ff;
}

.link.task_2,
.btn.task_2 {
  background-color: #ed7004;
}

.btn.task_2:hover {
  background-color: #fc9236;
}

.link.task_3,
.btn.task_3 {
  background-color: #e4032e;
}

.btn.task_3:hover {
  background-color: #fc365a;
}

.link.task_4,
.btn.task_4 {
  background-color: #95c11f;
}

.btn.task_4:hover {
  background-color: #bbe250;
}

.link.task_5,
.btn.task_5 {
  background-color: #f59c00;
}

.btn.task_5:hover {
  background-color: #ffb433;
}

.link.task_6,
.btn.task_6 {
  background-color: #832c87;
}

.btn.task_6:hover {
  background-color: #c865cd;
}

.title.task_1 {
  color: #00a4e2;
}

.title.task_2 {
  color: #ed7004;
}

.title.task_3 {
  color: #e4032e;
}

.title.task_4 {
  color: #95c11f;
}

.title.task_5 {
  color: #f59c00;
}

.title.task_6 {
  color: #832c87;
}
</style>
