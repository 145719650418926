<template>
  <!--
<div class="finished-container">
    <img :src="require('@/assets/logos/logo_syntein.svg')" class="logo-finish" />
    <canvas id="canvas"></canvas>
    <h1 class="finished-title">Gefeliciteerd</h1>
    <div>Jullie hebben de code gekraakt</div>
    <div>Tijd: {{ c_finish.time }}</div>
    <div>Met maar x foute codes</div>

</div>
-->
  <img :src="require('@/assets/logos/logo_syntein.svg')" class="logo-finish" />
  <div v-if="c_finish.finished == 'success'" class="finished-container">
    <audio
      autoplay
      loop
      id="audio-player"
      :src="require('@/assets/audio/fireworks.wav')"
    />
    <canvas id="canvas"></canvas>
    <h1 class="finished-title">Gefeliciteerd</h1>
    <div>Jullie hebben de code gekraakt</div>
    <div>Tijd: {{ c_finish.time }}</div>
    <div v-if="wrongCodes.length == 0">Wow in 1 keer de code gekraakt</div>
    <div v-else>Met maar {{ wrongCodes.length }} foute codes</div>
  </div>
  <div class="finished-container" v-else>
    <h1 class="finished-title fail">Helaas, uw tijd is op</h1>
  </div>
  <router-link :to="{ name: 'Start' }" class="btn_restart">Begin opnieuw</router-link>
</template>

<script>
import { initCanvas, loop } from "@/assets/js/fireworks.js";

// google analytics
import { useGtag } from "vue-gtag-next";

import { inject, onMounted } from "vue";
export default {
  name: "Finish",
  setup() {
    const { event } = useGtag();

    const c_finish = inject("c_finish");
    const wrongCodes = inject("wrongCodes");

    const playSound = inject("playSound");

    // c_finish.finished = 'fail'
    onMounted(() => {
      if (c_finish.finished == "success") {
        playSound("finish_success");

        // GA complete game
        event("finished_game", {
          event_category: "game",
          event_label: "Spel afgerond",
          completion_time: c_finish.time,
          completed: true,
        });

        let canvas = document.getElementById("canvas");
        initCanvas(canvas);
        loop();
      } else {
        playSound("finish_fail");

        // GA complete game
        event("finished_game", {
          event_category: "game",
          event_label: "Spel afgerond",
          completed: false,
        });
      }
    });

    return {
      c_finish,
      wrongCodes,
    };
  },
};
</script>

<style lang="css">
canvas {
  display: block;
  position: absolute;
  left: 0;
}

.finished-title {
  margin: 0;
  font-size: 6em;
}

.finished-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
}

.finished-container div {
  font-size: 2.2em;
  margin: 0.5em;
}

.logo-finish {
  width: 16vw;
  position: absolute;
  top: 3vh;
  right: 3vw;
  min-width: 300px;
}

.btn_restart {
  position: absolute;
  bottom: 2em;
  right: 1em;
  color: white;
}
</style>
