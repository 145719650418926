// state manager
// cd_ for default
const d_finish = {
    time: "00:00",
    finished: ""
}

import {
    reactive,
    ref
} from "vue"

const sfx = {
    btn_press: new Audio(require("@/assets/audio/btn_press.wav")),
    finish_fail: new Audio(require("@/assets/audio/finish_fail.wav")),
    finish_success: new Audio(require("@/assets/audio/finish_succes.flac")),
    icon_click: new Audio(require("@/assets/audio/icon_click.ogg")),
    show_tip: new Audio(require("@/assets/audio/show_tip_2.wav")),
    wrong_code: new Audio(require("@/assets/audio/wrong_code.wav")),
    task_complete: new Audio(require("@/assets/audio/task_complete.wav")),
    switch_tip: new Audio(require("@/assets/audio/switch_tip.wav")),
}


export default function getStates() {

    const isFullscreen = new ref(false)

    const subtasksDone = new ref(false)

    const availableTips = new ref([])

    const completedLetters = new reactive({})

    const c_finish = new reactive(d_finish)

    const smallTimer = new ref(false)

    const currentMusic = new ref("")

    const wrongCodes = new ref([])

    const currentCode = new ref('')

    const playSound = (effect) => {
        let sound = sfx[effect]
        if (sound) {
            // let audio = new Audio(sound)
            sound.currentTime = 0;
            sound.play()
        }
    }


    return {
        isFullscreen,
        subtasksDone,
        availableTips,
        completedLetters,
        c_finish,
        smallTimer,
        currentMusic,
        wrongCodes,
        currentCode,

        playSound
    }
}