<template>
  <div class="numpad-container">
    <div class="numpad">
      <!-- Numbers -->
      <div class="num_row">
        <button :class="'btn task_' + props.taskid" @click="addnum(7)">
          7
        </button>
        <button :class="'btn task_' + props.taskid" @click="addnum(8)">
          8
        </button>
        <button :class="'btn task_' + props.taskid" @click="addnum(9)">
          9
        </button>
      </div>
      <div class="num_row">
        <button :class="'btn task_' + props.taskid" @click="addnum(4)">
          4
        </button>
        <button :class="'btn task_' + props.taskid" @click="addnum(5)">
          5
        </button>
        <button :class="'btn task_' + props.taskid" @click="addnum(6)">
          6
        </button>
      </div>
      <div class="num_row">
        <button :class="'btn task_' + props.taskid" @click="addnum(1)">
          1
        </button>
        <button :class="'btn task_' + props.taskid" @click="addnum(2)">
          2
        </button>
        <button :class="'btn task_' + props.taskid" @click="addnum(3)">
          3
        </button>
      </div>
      <div class="num_row">
        <button :class="'btn task_' + props.taskid" @click="addnum('<')">
          &lt;
        </button>
        <button :class="'btn task_' + props.taskid" @click="addnum(0)">
          0
        </button>
      </div>
    </div>
  </div>
  <div
    class="currentCode isTask"
    :class="'task_' + props.taskid"
    v-if="props.letter != 'finish'"
  >
    {{ currentCode }}
  </div>
</template>

<script>
import { ref, inject } from "vue";
import router from "@/router";

// google analytics
import { useGtag } from "vue-gtag-next";

export default {
  props: {
    code: {
      type: String,
      required: true,
    },
    letter: {
      type: String,
      default: "finish",
    },
    taskid: {
      type: String,
      default: "0",
    },
  },
  setup(props) {
    const { event } = useGtag();

    const s_content = inject("s_content");
    const task_letters = inject("completedLetters");
    const c_finish = inject("c_finish");
    const stopTime = inject("stopTime");

    const currentCode = inject("currentCode");
    const wrongCodes = inject("wrongCodes");

    const playSound = inject("playSound");

    const lastcode = new ref(0);
    const controlcode = s_content.controlcode;

    function addnum(num) {
      playSound("btn_press");
      if (num == "<") {
        // remove last character
        currentCode.value = currentCode.value.slice(
          0,
          currentCode.value.length - 1
        );
      } else {
        // add number
        currentCode.value += num;

        // check if code = correct else check maxlength
        if (currentCode.value == props.code) {
          // code correct

          if (props.letter != "finish") {
            playSound("task_complete");
            // add letter

            // GA complete task x
            event("completed_task_" + props.taskid, {
              event_category: "game",
              event_label: "Taak " + props.taskid + " afgerond",
            });

            task_letters[props.taskid] = props.letter;
            currentCode.value = "";
            router.push({
              name: "GameMain",
            });
          } else {
            // final code

            currentCode.value = "";
            c_finish.finished = "success";
            stopTime();
            router.push({
              name: "Finish",
            });
          }
        } else if (
          lastcode.value == controlcode &&
          currentCode.value == controlcode &&
          props.letter == "finish"
        ) {
          // go to "beheer" view
          router.push({
            name: "Beheer",
          });
        } else if (currentCode.value.length >= props.code.length) {
          playSound("wrong_code");
          // create shake animation & timer circle red
          let app = document.querySelector("body");
          let timer = document.querySelector(".base-timer");
          app.classList.add("shake_animation");
          timer.classList.add("red-timer");
          setTimeout(function () {
            app.classList.remove("shake_animation");
            timer.classList.remove("red-timer");
          }, 200);

          // wrong code
          if (props.letter == "finish") {
            wrongCodes.value.push(currentCode.value);
          }
          lastcode.value = currentCode.value;
          currentCode.value = "";
        }
      }
    }

    return {
      currentCode,
      addnum,
      props,
    };
  },
};
</script>

<style>
/* numpad */
.numpad-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.numpad {
  width: 300px;
  margin: 0.5rem;
}

.num_row {
  display: flex;
}

.num_row button {
  width: 100%;
  height: 4.5rem;
}

/* Code display */

.isTask {
  height: 3rem;
  border: 2px solid;
  border-radius: 5px;
  margin: 0.5rem;
  font-size: 2em;
  line-height: 1.5em;
}

.currentCode.task_1 {
  border-color: #00a4e2;
}

.currentCode.task_2 {
  border-color: #ed7004;
}

.currentCode.task_3 {
  border-color: #e4032e;
}

.currentCode.task_4 {
  border-color: #95c11f;
}

.currentCode.task_5 {
  border-color: #f59c00;
}

.currentCode.task_6 {
  border-color: #832c87;
}
</style>
