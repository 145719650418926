import { createWebHashHistory, createRouter } from "vue-router";
import Start from "@/views/Start.vue";
import Game from "@/views/Game.vue";
import GameMain from "@/views/GameMain.vue";
import GameTask from "@/views/GameTask.vue";
import Beheer from "@/views/Beheer.vue";
import Finish from "@/views/Finish.vue";

const routes = [
    {
        path: "/",
        name: "Start",
        component: Start,
    },
    {
        path: "/game",
        name: "Game",
        component: Game,
        children: [
            {
                path: '',
                name: 'GameMain',
                component: GameMain,
            },
            {
                path: 'task/:id',
                name: 'GameTask',
                component: GameTask,
            }
        ]
    },
    {
        path: "/beheer",
        name: "Beheer",
        component: Beheer
    },
    {
        path: "/finish",
        name: "Finish",
        component: Finish
    },
];


const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.afterEach(() => {
    window.scrollTo(0, 0)
})


export default router;