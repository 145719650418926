<template>
<div class="base-timer " :class="{ 'small': smallTimer, 'subtasksDone': subtasksDone }">
    <svg class=" svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">

        <path :stroke-dasharray="circleDasharray()" class=" path-remaining" d="
            M 50, 50
            m -45, 0
            a 45,45 0 1,0 90,0
            a 45,45 0 1,0 -90,0
          "></path>
    </svg>
    <span class=" label">
        <!-- Remaining time label -->
        {{ time_left }}
    </span>
</div>
</template>

<script>
import {
    inject,
} from 'vue'

export default {
    setup() {
        const time_left = inject('time_left')
        const circleDasharray = inject('circleDasharray')
        const smallTimer = inject('smallTimer')
        const subtasksDone = inject("subtasksDone")

        return {
            time_left,
            circleDasharray,
            smallTimer,
            subtasksDone
        }
    }

}
</script>

<style>
/* Sets the containers height and width */
.base-timer {
    position: relative;
    width: 30em;
    height: fit-content;

}

/* Removes SVG styling that would hide the time label */
.base-timer .circle {
    fill: none;
    stroke: none;
}

/* The SVG path that displays the timer's progress */
.base-timer .path-elapsed {
    stroke-width: 7px;
    stroke: grey;
    padding: 0;
}

.base-timer .label {
    position: absolute;

    /* Size should match the parent container */
    width: 100%;
    height: 100%;
    /* Keep the label aligned to the top */
    top: 0;
    /* Create a flexible box that centers content vertically and horizontally */
    display: flex;
    align-items: center;
    justify-content: center;
    /* Sort of an arbitrary number; adjust to your liking */
    font-size: 8em;
    color: #808080;
}

.base-timer .path-remaining {
    /* Just as thick as the original ring */
    stroke-width: 7px;
    /* Rounds the line endings to create a seamless circle */
    /* Makes sure the animation starts at the top of the circle */
    transform: rotate(90deg);
    transform-origin: center;
    /* One second aligns with the speed of the countdown timer */
    transition: .3s linear all;
    /* Allows the ring to change color when the color value updates */
    /*stroke: rgb(65, 184, 131);*/
    stroke: #808080;
    fill: none;
}

.base-timer.small {
    width: 125px;
}

.base-timer.small .label {
    font-size: 2.5em;
}

.base-timer.subtasksDone {
    width: 19em;
}

.base-timer.subtasksDone .label {
    font-size: 5em;
}
</style>
