<template>
  <div class="row tips">
    <div class="">
      <button
        class="btn change-tip prev"
        :class="'task_' + task_id"
        @click="changeSlide(-1)"
        style="display: none"
      >
        <img class="img_prev" :src="require('@/assets/icons/arrow.svg')" />
      </button>
    </div>
    <div
      class="tip-slide"
      v-for="tip in availableTips"
      :key="tip"
      style="display: none"
    >
      Tip: {{ tip }}
    </div>
    <div class="">
      <button
        class="btn change-tip next"
        :class="'task_' + task_id"
        @click="changeSlide(1)"
        style="display: none"
      >
        <img class="img_next" :src="require('@/assets/icons/arrow.svg')" />
      </button>
    </div>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import { inject, watch, nextTick, ref, onMounted } from "vue";
export default {
  setup() {
    const route = useRoute();
    const task_id = new ref(route.params.id);
    watch(
      () => route.params.id,
      () => {
        task_id.value = route.params.id;
      }
    );

    const availableTips = inject("availableTips");
    const playSound = inject("playSound");

    // watch for new tip --> add to tippies if new
    watch(
      () => availableTips.value.length,
      () => {
        // tippies.value.unshift(newTip)

        // call after dom is updated
        nextTick(() => {
          showSlide(availableTips.value.length);
        });
      }
    );

    onMounted(() => {
      if (availableTips.value.length > 0) {
        showSlide(availableTips.value.length);
      }
    });

    // Tips as different slides
    var slideIndex = 0;

    function changeSlide(n) {
      playSound("switch_tip");
      showSlide((slideIndex += n));
    }

    function showSlide(n) {
      let btn_next = document.querySelector(".change-tip.next");
      let btn_prev = document.querySelector(".change-tip.prev");

      var i;
      let slides = document.getElementsByClassName("tip-slide");

      slideIndex = n;
      // show tip logic
      if (n < 0) {
        slideIndex = slides.length - 1;
      }
      if (n > slides.length) slideIndex = 0;
      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
      }
      slides[slideIndex - 1].style.display = "block";

      // show/hide next & prev btn
      if (slideIndex == slides.length) {
        btn_next.style.display = "none";
      } else {
        btn_next.style.display = "block";
      }

      if (slideIndex == 1) {
        btn_prev.style.display = "none";
      } else {
        btn_prev.style.display = "block";
      }
    }

    return {
      availableTips,
      changeSlide,
      task_id,
    };
  },
};
</script>

<style>
.row.tips {
  margin-top: 1em;
  margin-bottom: 1em;
}

.change-tip {
  line-height: 1.99em !important;
  font-weight: bold;
  background: none !important;
}

.change-tip:hover {
  background: none;
  opacity: 0.6;
}

.tip-slide {
  font-size: 1.8em;
  max-width: 60vw;
}

.img_next {
  transform: rotateY(180deg);
}
</style>
