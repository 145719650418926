<template>
  <div class="container">
    <div class="row control-row">
      <div class="col">
        <!-- Game Setttings -->
        <div class="row sett_row">
          <label class="lbl_sett" for="max_time">Max Tijd</label>
          <input type="text" id="max_time" v-model="s_custom.max_time" />
        </div>

        <div class="row sett_row">
          <label class="lbl_sett" for="code">Code</label>
          <input type="text" id="code" v-model="s_custom.code" />
        </div>

        <div class="row sett_row">
          <label class="lbl_sett" for="music">Muziek</label>
          <div class="input-holder">
            <label class="switch">
              <input type="checkbox" id="music" v-model="s_custom.music" />
              <span class="slider round"></span>
            </label>
          </div>
        </div>
      </div>
      <div class="col">
        <!-- Letters & Codes -->
        <div class="row task_row" v-for="i in 6" :key="i">
          <!-- icon -->
          <img class="icon_small" :src="require('@/assets/icons/' + s_content[i].icon)" />
          <input
            type="text"
            :id="'task_' + i + '-code'"
            v-model="s_custom.tasks[i].code"
          />
          <input
            type="text"
            :id="'task_' + i + '-letter'"
            v-model="s_custom.tasks[i].letter"
          />
        </div>
      </div>
      <div class="col start_col">
        <!-- syntein logo -->
        <img :src="require('@/assets/logos/logo_syntein.svg')" class="logo-beheer" />
        <!-- Start btn-->
        <router-link :to="{ name: 'GameMain' }" class="btn_start">Start</router-link>
      </div>
    </div>
  </div>
  <span class="version-number">V: 1.0.4</span>
</template>

<script>
import { inject } from "vue";
export default {
  setup() {
    const s_custom = inject("s_custom");
    const s_content = inject("s_content");

    return {
      s_custom,
      s_content,
    };
  },
};
</script>

<style>
.row.control-row {
  justify-content: space-around;
}

.row.task_row {
  margin-top: 1em;
}

.lbl_sett {
  font-size: 2em;
  flex-grow: 1;
  text-align: right;
}

.input-holder,
input[type="text"] {
  background-color: transparent;
  font-size: 1.8em;
  text-align: center;
  color: white;
  /*flex-grow: 10;*/
  margin: 5px;
  width: 180px;
}

.task_row input[type="text"] {
  max-width: 5em;
}

.sett_row input[type="text"] {
  max-width: 6em;
}

.icon_small {
  width: 30px;
  height: 30px;
  margin: auto;
}
</style>
<style lang="css" scoped>
/* slider styling */
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.version-number {
  position: absolute;
  bottom: 20px;
  right: 25px;
  font-size: 1.5em;
}
</style>
