<template>
  <div class="wrong_codes">
    <span v-for="code in wrongCodes" :key="code.index" class="wrong_code">
      {{ code }}
    </span>
  </div>
  <div class="currentCode isMain">
    {{ currentCode }}
  </div>
</template>

<script>
import { inject } from "vue";
export default {
  setup() {
    const wrongCodes = inject("wrongCodes");
    const currentCode = inject("currentCode");

    return {
      wrongCodes,
      currentCode,
    };
  },
};
</script>

<style lang="css">
.wrong_codes {
  height: 100%;
  height: calc(100% - 5em);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  mask-image: linear-gradient(to top, black 0%, transparent 100%);
  width: min-content;
  margin: auto;
}

.wrong_code {
  color: red;
  font-size: 4em;
  text-align: right;
}

.currentCode.isMain {
  font-size: 4em;
  height: 4rem;
  text-align: right;
  width: min-content;
  margin: auto;
}
</style>
