<template>
  <div class="row firstrow mainrow">
    <div class="timer-col">
      <timer />

      <div class="maincontent" v-if="subtasksDone">
        Ga naar
        <a href="https://www.syntein.nl/MHUBER" target="_blank"
          >https://www.syntein.nl/MHUBER</a
        >
        TIP: leg de juiste 6 cijfers bij de icoontjes van het spinnenweb. Succes, jullie
        zijn bijna bij de oplossing!
      </div>
    </div>
    <div class="codes-col">
      <wrongcodes />
    </div>
    <div class="num-col">
      <img :src="require('@/assets/logos/logo_syntein.svg')" class="logo" />
      <numpad v-bind:code="code" />
    </div>
  </div>
  <tips />
</template>

<script>
import numpad from "@/components/Numpad.vue";
import timer from "@/components/Timer.vue";
import tips from "@/components/Tips.vue";
import wrongcodes from "@/components/WrongCodes.vue";

import { inject } from "vue";

import { onBeforeRouteLeave } from "vue-router";
export default {
  components: {
    tips,
    numpad,
    timer,
    wrongcodes,
  },

  setup() {
    const s_custom = inject("s_custom");
    const s_content = inject("s_content");
    const subtasksDone = inject("subtasksDone");

    const currentCode = inject("currentCode");

    const smallTimer = inject("smallTimer");
    smallTimer.value = false;

    const code = s_custom.code;
    const mainContent = s_content.mainContent.content;

    onBeforeRouteLeave(function () {
      currentCode.value = "";
    });

    return {
      code,
      subtasksDone,
      mainContent,
    };
  },
};
</script>

<style lang="css">
.logo {
  max-width: calc(300px - 1rem);
}

.codes-col {
  max-height: 30rem;
}

.maincontent {
  text-align: center;
  font-size: 1.9em;
  max-width: 23rem;
  white-space: normal;
  animation: flashing 1s linear infinite;
}
.maincontent a {
  /* color: #808080; */
}

@keyframes flashing {
  50% {
    opacity: 0.3;
  }
}
</style>
