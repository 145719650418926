import { createApp, readonly } from 'vue'
import App from './App.vue'
import router from './router'
import VueGtag from "vue-gtag-next";

import getSettings from './settings'
import getStates from './states'

var settings = getSettings();
var states = getStates();

var app = createApp(App)


document.body.style.backgroundImage = 'url(' + require("@/assets/images/static.gif") + ')';

app.use(VueGtag, {
    property: {
        id: 'G-LVR809C8M1',
        params: {
            send_page_view: false,
        }
    }
})
app.use(router)

// provide settings
app.provide('s_custom', settings.custom)
// app.provide('s_finish', settings.finish)
app.provide('s_content', readonly(settings.content))
app.provide('s_tips', readonly(settings.tips))
app.provide('s_music', readonly(settings.music))

//provide states
Object.entries(states).forEach(([name, state]) => {
    app.provide(name, state)
});



app.mount('#app')

// mute tab when not in focus
var sounds = document.getElementsByTagName("audio");
window.onblur = function () {
    for (var i = 0; i < sounds.length; ++i) {
        sounds[i].muted = true;
    }
}

window.onfocus = function () {
    for (var i = 0; i < sounds.length; ++i) {
        sounds[i].muted = false;
    }
}

// js & css for particle effects

require('@/assets/css/particles.css')

// responsice styling
require('@/assets/css/responsive.css')
