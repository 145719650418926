<template>
  <router-view />
  <div class="toggle-fullscreen" @click="toggleFullscreen">
    <img :src="require('@/assets/icons/fullscreen-close.svg')" v-if="isFullscreen" />
    <img :src="require('@/assets/icons/fullscreen-open.svg')" v-else />
  </div>
</template>

<script>
import { inject } from "vue";
export default {
  name: "App",
  setup() {
    const isFullscreen = inject("isFullscreen");
    const elem = document.documentElement;

    function toggleFullscreen() {
      if (isFullscreen.value == true) {
        closeFullscreen();
      } else {
        openFullscreen();
      }

      isFullscreen.value = !isFullscreen.value;
    }

    function openFullscreen() {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen();
      }
    }

    function closeFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE11 */
        document.msExitFullscreen();
      }
    }

    window.onbeforeunload = function () {
      return confirm();
    };

    return {
      isFullscreen,
      toggleFullscreen,
    };
  },
};
</script>

<style>
body {
  background-color: black;
  color: white;
  font-family: "Londrina Solid", "Open Sans", sans-serif;
  margin: 0;
}

#app {
  text-align: center;
  margin: 0;
  height: 92vh;
  padding: 3vh 0vw;
  width: 100%;
  display: flex;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 1200px;
  height: 90%;
  margin: auto;
  max-height: 900px;
}

.row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex: 0 0 auto;
}

.row.firstrow {
  margin-top: 0;
}

.row.mainrow {
  margin-bottom: 2em;
}

.btn {
  cursor: pointer;
  margin: 3px;
  height: 2em;
  font-size: 2em;
  color: #fff;
  background-color: #262626;
  border: none;
  border-radius: 10px;
}

.btn:hover {
  background-color: #404040;
}

.btn:focus {
  outline: none;
  box-shadow: none;
}

.title {
  font-size: 4em;
  margin: auto 0;
  height: min-content;
}

.content {
  font-size: 2em;
  width: 800px;
  width: calc(100% - 400px);
  white-space: pre-line;
}

.toggle-fullscreen {
  position: fixed;
  right: 25px;
}

.toggle-fullscreen:hover {
  opacity: 0.5;
}

.toggle-fullscreen img {
  width: 3em;
}
</style>
